
import HeaderMain from '@/components/shared/HeaderMain.vue';
import { defineComponent, ref, computed, onMounted } from 'vue';
import {
  logoTwitter,
  logoFacebook,
  logoLinkedin,
  linkOutline,
  mail,
  call,
} from 'ionicons/icons';
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonButton,
  IonImg,
} from '@ionic/vue';
import { Exhibitor } from '@/types/interface';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { openToast } from '@/helpers/toast';
import bizvento from '@/services/bizvento';

export default defineComponent({
  name: 'App',
  components: {
    HeaderMain,
    IonContent,
    IonPage,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonIcon,
    IonButton,
    IonImg,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const id = computed(() => route.params.id);
    const isLoading = ref<boolean>(false);
    const exhibitorAsset = ref<string | undefined>();

    const fallbackImage = '../assets/fallback/exhibitor.png';

    const exhibitor = computed(() => {
      return (store.state.exhibitors.find(
        (exhibitor: Exhibitor) => exhibitor.id === id.value
      ) || {}) as Exhibitor;
    });

    const isFavourite = computed(() => {
      return store.state.favouriteExhibitors.find(
        (exhibitor: Exhibitor) => exhibitor.id === id.value
      );
    });

    const addToFavourite = async () => {
      isLoading.value = true;
      try {
        await bizvento.user.createFavourite(
          process.env.VUE_APP_EVENT_ID,
          store.state.user.uid,
          String(exhibitor.value.id),
          'exhibitor'
        );
        store.commit('SET_FAVOURITE_EXHIBITOR', exhibitor.value);
        return openToast(
          'Exhibitor has been added to favourites list',
          2000,
          'bottom',
          'success'
        );
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const removeFromFavourite = async (): Promise<void> => {
      isLoading.value = true;
      try {
        await bizvento.user.deleteFavourite(
          process.env.VUE_APP_EVENT_ID,
          store.state.user.uid,
          String(exhibitor.value.id)
        );
        store.commit('REMOVE_FAVOURITE_EXHIBITOR', exhibitor.value);
        return openToast(
          'Exhibitor has been removed from favourites list',
          2000,
          'bottom',
          'danger'
        );
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const openExternalUrl = function (externalUrl: string) {
      window.open(externalUrl, '_system');
    };

    onMounted(async () => {
      try {
        if (exhibitor.value.id) {
          const exhibitorAssets = await bizvento.exhibitor.getAssets(
            process.env.VUE_APP_EVENT_ID,
            exhibitor.value.id
          );

          if (exhibitorAssets && exhibitorAssets.length > 0) {
            exhibitorAsset.value = exhibitorAssets[0].url;
          }
        }
      } catch (e) {
        console.log(e);
      }
    });

    return {
      removeFromFavourite,
      addToFavourite,
      openExternalUrl,
      exhibitor,
      isLoading,
      logoTwitter,
      logoFacebook,
      logoLinkedin,
      linkOutline,
      mail,
      call,
      isFavourite,
      fallbackImage,
      exhibitorAsset,
    };
  },
});
